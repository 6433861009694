.signUpModalWrapper {
    & .modal-dialog {
        width: 100%;

        @include md {
            width: 100%;
            max-width: 789px;
        }
    }

    & .modal-content {
        background-color: $theme1 !important;
        border-radius: 10px !important;
        min-height: 640px;
    }

    & .modalHeader {
        border-bottom: 0 !important;
    }

    & .modalBody {
        padding: 0;

        @include md {
            display: flex;
            align-items: stretch;
        }

        & .heightDiv {
            width: auto;

            @include md {
                height: auto !important;
                width: 100%;
            }
        }

        & .modalBanner {
            width: 100%;

            // height: auto;
            // height: 650px;
            @include md {
                height: 100%;
            }

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        & .damble {
            width: 87px;
            height: 15.27px;
            object-fit: contain;
            margin: auto;

            @include md {
                height: 31.38px;
                width: 178.76px;
                margin: auto;
            }

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        & .signupForm {
            padding: 5px 22px 22px 22px;

            @include md {
                padding: 25px 4px 25px 4px;
            }

            & .header {
                @include md {
                    margin-bottom: 15px;
                }

                margin-bottom: 5px;

                & .headerTxt {
                    font-size: 20px;
                    font-weight: 600;
                    color: white;
                }

                & .closeBtn {
                    width: 14px;
                    height: 14px;
                    margin-bottom: 7px;
                    cursor: pointer;

                    & img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            & .formbody {
                & .tabs {
                    & .tab-buttons {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        margin-bottom: 6px;
                        margin-top: 10px;

                        @include md {
                            margin-top: 0;
                        }

                        & .tabBtn {
                            width: 50%;
                            height: 45px;
                            border-radius: 5px;
                            font-size: 14px;
                            font-weight: 500;
                            color: $theme5;
                            background-color: $theme8;
                            border-top: 3px solid #363B4B;
                            border-left: 3px solid #363B4B;
                            border-right: 3px solid #363B4B;
                            border-bottom: 5px solid #363B4B;
                        }

                        & .active {
                            width: 50%;
                            height: 45px;
                            border-radius: 5px;
                            font-size: 14px;
                            font-weight: 500;
                            color: white;
                            background-color: $theme8;
                            border: 0;
                            border-top: 3px solid #616572;
                            border-left: 3px solid #616572;
                            border-right: 3px solid #616572;
                            border-bottom: 5px solid #616572;
                        }
                    }

                    & .tab-content {}
                }

                & .accordionItem {
                    border: 0;

                    & .accordionHeader {
                        background-color: $theme1 !important;
                        border: 0 !important;

                        & .accordion-button {
                            background-color: $theme1 !important;
                            border: 0 !important;
                            font-size: 13px;
                            font-weight: 600;
                            color: $theme5;
                            padding: 10px 0 7px 0;
                            box-shadow: unset;

                            &::after {
                                display: none;
                            }

                            &:focus {
                                box-shadow: unset;
                            }

                            &:focus {
                                outline: 0;
                            }
                        }
                    }

                    & .accordionBody {
                        padding: 0;
                        background-color: $theme1;
                        border-top: 0;
                    }
                }

                & .checkboxInp {
                    accent-color: $theme11;
                    background-color: $theme1;
                    border-color: $theme11;
                    border-radius: 2px;

                    &:focus {
                        box-shadow: none;
                    }
                }

                & .checkboxLbl {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 15.6px;
                    color: $theme5;

                    & span {
                        color: white;
                    }
                }

                & .signUpBtn {
                    border-radius: 4.61px;
                    border-color: white;
                    width: 100%;
                    height: 41.5px;
                    background-color: $theme1;

                    & img {
                        height: 19.87px;
                        width: 119.89px;
                        object-fit: contain;
                    }
                }

                & .anchortext {
                    font-size: 12px;
                    font-weight: 600;
                    color: $theme5;
                    text-decoration: none;

                    & span {
                        font-size: 15px;
                        font-weight: 600;
                        color: $theme11;
                        margin-left: 7px;
                    }
                }

                & .divider {
                    width: 100%;
                    height: 1px;
                    background-color: $theme5;
                }

                & .dividerTxt {
                    font-size: 13px;
                    font-weight: 500;
                    color: $theme5;
                }

                & .socialLinks {
                    width: 32px;
                    height: 32px;
                    border-radius: 3px;
                    display: block;
                    border: 1px solid $theme5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    & img {
                        height: 12px;
                        object-fit: contain;
                    }
                }
            }

            & .nav-pills {
                background-color: #191D27;
                width: fit-content;
                border-radius: 25px;
                margin-bottom: 18px;
                padding: 0;

                & .nav-item {
                    & .nav-link {
                        color: #6A798C;
                        font-weight: 700;
                        font-size: 12px;
                        padding: 11px 35px;
                        border-radius: 20px !important;
                        margin: 5px;

                        @include sm {
                            padding: 11px 47px;
                        }

                        &.active {
                            background-color: #0E95FA !important;
                            color: white !important;
                            border: 0 !important;
                        }
                    }
                }
            }

            & .direct-login {
                height: 52px;
                width: 52px;
                border-radius: 50%;
                background-color: #2F364C;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & .all-methods {
                color: #6A798C;
                font-weight: 500;
                font-size: 15px;
                background-color: #202733;
                position: relative;
                text-decoration: none;
            }

            & .line {
                width: 50px;
                height: 1px;
                background-color: #6A798C;
                margin: 0 10px;
            }

            & .tab-content {
                & .input-box {
                    border: 1px solid #6A798C;
                    background-color: #191D27;
                    border-radius: 10px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    padding: 13px;

                    & input {
                        border: 0;
                        background-color: transparent !important;
                        width: 100%;
                        padding: 10px;
                        color: #fff !important;

                        &:focus {
                            box-shadow: none !important;
                            outline: 0 !important;
                            border: 0 !important;
                        }

                        &::placeholder {
                            color: #B2B6C5;
                            font-weight: 500;
                            font-size: 15px;
                            background-color: transparent !important;
                        }
                    }
                }

                & .input-box-2 {
                    background-color: #2F364C;
                    border-radius: 10px;
                    height: 60px;
                    display: flex;
                    align-items: center;

                    & .dropdown {
                        width: 100%;
                        padding: 13px;

                        & img {
                            top: 25px;
                            left: 10px;
                        }

                        & #customSelect {
                            background-color: transparent;
                            font-weight: 500;
                            font-size: 15px;
                            color: #fff;
                            text-align: left;
                            padding: 0;
                        }

                        & .selected-item {
                            color: #0E95FA;
                            font-weight: 400;
                            font-size: 12px;

                        }

                        & .dropdown-menu {
                            inset: 0 auto auto -12px !important;
                            background-color: #2F364C;
                            border-radius: 10px !important;
                            padding: 12px;

                            & .dropdown-search {
                                & i {
                                    color: #B2B6C5;
                                    font-size: 16px;
                                    top: 26px;
                                    z-index: 1;
                                    left: 20px;
                                }

                                & input {
                                    background-color: #191D27;
                                    border-radius: 10px;
                                    width: 100%;
                                    padding: 10px;
                                    color: #fff;
                                    border: 0;
                                    padding-left: 30px;

                                    &::placeholder {
                                        color: #6A798C;
                                        font-weight: 500;
                                        font-size: 15px;
                                    }

                                    &:focus {
                                        box-shadow: none !important;
                                        outline: 0 !important;
                                        border: 0 !important;
                                    }
                                }
                            }

                            & .listItems-scroll {
                                overflow-y: auto;
                                max-height: 110px;
                                margin-top: 10px;

                                & .dropdown-item {
                                    color: #6A798C;
                                    font-weight: 500;
                                    font-size: 15px;
                                    padding: 8px 12px;

                                    &:hover {
                                        background-color: #191D27 !important;
                                        border-radius: 10px;
                                    }
                                }

                                &::-webkit-scrollbar {
                                    width: 8px;
                                    display: block;
                                }

                                &::-webkit-scrollbar-track {
                                    background: #191D27;
                                    border-radius: 10px;
                                }

                                &::-webkit-scrollbar-thumb {
                                    background: #6A798C;
                                    border-radius: 10px;
                                }
                            }
                        }

                        & .arrow-icon {
                            color: #6A798C;
                            top: 20px;
                            right: 15px;
                        }
                    }

                    & input {
                        border: 0;
                        background-color: transparent;
                        width: 100%;
                        padding: 10px;
                        color: #fff;

                        &:focus {
                            box-shadow: none !important;
                            outline: 0 !important;
                            border: 0 !important;
                        }

                        &::placeholder {
                            color: #B2B6C5;
                            font-weight: 500;
                            font-size: 15px;
                        }
                    }
                }

                & .forgot-password {
                    font-weight: 500;
                    font-size: 13px;
                    text-decoration: underline;
                    color: #0E95FA;
                }

                & .create-account {
                    font-weight: 600;
                    font-size: 15px;
                    color: #fff;
                    background-color: #0E95FA;
                    border: 0;
                    border-radius: 25px;
                    width: 100%;
                    padding: 12px 0px;
                }

                & .confirm-text {
                    font-weight: 400;
                    font-size: 13px;
                    color: #6A798C;
                }
            }
        }

        & .closeBtnmobile {
            position: absolute;
            right: 20px;
            top: 20px;
            width: 14px;
            height: 14px;

            & img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    & .modalFooter {
        border-top: 0 !important;
    }
}

.commonInp {
    background-color: $theme8;
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 0;
    padding-left: 18px;
    padding-right: 30px;
    font-size: 13px;
    font-weight: 500;
    color: $theme5;

    &.commonInpNumber {
        padding-left: 80px;
    }

    &::placeholder {
        font-size: 13px;
        font-weight: 500;
        color: $theme5;
    }

    &:focus {
        outline: 0;
    }

    &:-webkit-autofill {
        -webkit-text-fill-color: $theme5 !important;
        box-shadow: 0 0 0 50px $theme8 inset !important;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-background-origin: unset;
        caret-color: $theme5;
    }

    &.withdrawInp {
        color: white;

        @include lg {
            font-size: 16px;
            font-weight: 600;
            height: 50px;

            &::placeholder {
                color: white;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}

.eyeImg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    cursor: pointer;

    & img {
        height: 10px;
        object-fit: contain;
    }
}

.dropWrapper {
    &.active {
        & .counteryCodeWrapper {
            display: block;
            background-color: #2F364C;
        }
    }
}

.numberWrapper {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    color: white;
    padding-right: 9px;

    & svg {
        width: 10px;
        height: 10px;
        object-fit: contain;
        margin-left: 5px;
    }
}

.commonInpSearch {
    padding-left: 35px;
    background-color: #191d27 !important;
}

.searchImg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        color: white;
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
}

.countryList {
    border: 1px solid transparent;
    border-radius: 4px;
    transition: 0.3s linear;
    cursor: pointer;

    &:hover {
        border: 1px solid $theme3;
        border-radius: 4px;
    }
}

.counteryCodeWrapper {
    display: none;
    background-color: $theme2;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    top: 100%;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 255px;
}

.numberTxt {
    font-size: 14px;
    font-weight: 500;
    color: white;
    border-right: 1px solid white;
    padding-right: 12px;
    min-width: 50px;
}

.counteryName {
    font-size: 14px;
    font-weight: 500;
    color: #5D6270;
}

.form-message-icon {
    padding-left: 10px;
}



.loginSuccessModal {
    & .modal-content {
        background: linear-gradient(180deg, #002789 0%, #000D2C 100%) !important;
        border-radius: 10px !important;
        border: 0 !important;

        & .tittle-1 {
            font-size: 24px;
            color: #fff;
        }

        & .tittle-2 {
            font-size: 20px;
            color: #fff;
        }
    }
}

// .customInput{

// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
    background-color: #000 !important;
    caret-color: #fff;
}